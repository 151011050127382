.MuiOutlinedInput-root {
  border-radius: 0 !important;
  outline: 0 !important;
}


.MuiAppBar-root {
  box-shadow: unset !important;
}

.MuiOutlinedInput-input {
  padding: 0.575rem 0.75rem !important;
}

.MuiAccordionSummary-root {
  padding: 0.75rem 3rem 0 3rem !important;
}

.MuiAccordionDetails-root {
  padding: 0 7rem 0.75rem 7rem !important;
}

.MuiAccordion-root {
  border-radius: 0.75rem !important;
}

.Mui-expanded {
  margin: 0.5rem 0 !important;
}

.MuiAccordionSummary-content {
  margin-left: 40px !important;
}


.theMobileLogo {
  text-align: center;
  width: 100%;
  display: block;
  padding: 1rem 0;
}

.theMobileLogo img {
  width: 120px;
  margin: 0 0.2rem;
}

.language_bar {
  background: #EA4843;
  color: white;
  margin-left: 10px;
}

.language_bar .lang_container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.language_bar .lang {

  cursor: pointer;
  padding: 0 10px;
}

.language_bar .lang.active {
  background-color: #EC7C79;
}

@media screen and (min-width: 576px) {
  .theMobileLogo {
    margin-left: 8rem;
  }
}

@media screen and (min-width: 768px) {
  .theMobileLogo {
    margin-left: 6rem;
  }
}

@media screen and (min-width: 768px) {
  .theMobileLogo img {
    width: 120px;
    margin: unset;
  }
}

.language_bar_other {
  background: #d6bf84;
  color: white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.theOnlinePersonBtn {
  /* border: 1px solid rgb(212, 212, 212); */
  padding: 5px 0;
  border-radius: 4px;
  width: 40%;
  text-align: center;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  color: #000000;
  text-decoration: unset;
align-items: center;

}

@media screen and (min-width:992px) {
  .theOnlinePersonBtn {
    width: 10%;
  }
}

.theOnlineDot{
  font-size: small;
  color: rgb(32, 255, 50);
  margin-top: 5px;
}